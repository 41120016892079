import React from "react"
import {AppFormContext} from "./AppForm";
import _ from "lodash";
import {TextField} from "@mui/material";

export default function AppFormField({fieldPath, children}) {

    const {formControl, formModel} = React.useContext(AppFormContext)
    const fieldConfig = _.get(formModel, fieldPath)

    const InputComponent = fieldConfig?.inputComponent || TextField
    const inputProps = fieldConfig?.inputProps

    const registerOptions = fieldConfig?.registerOptions
    const register = formControl.register(fieldPath, registerOptions)

    return (
        <InputComponent {...inputProps} {...register} >
            {children}
        </InputComponent>)
}