import React from "react";
import {Button, Checkbox, Grid, Paper, TextField, Typography} from "@mui/material";
import {useLoginMutation} from "../networking/queries";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthStore} from "../stateManagement";
import {useForm} from "react-hook-form";
import AppForm from "../components/AppForm";
import AppFormField from "../components/AppFormField";
import AppSnackbar from "../components/AppSnackbar";

function LoginPage() {

    const [isAuthenticated, user] = useAuthStore(state => [state.isAuthenticated, state.user])
    const navigate = useNavigate()
    const { state: locationState } = useLocation()

    const loginMutation = useLoginMutation()

    const prevLocationPath = locationState?.prevLocation?.pathname || '/'
    const prevLocationSearch = locationState?.prevLocation?.search || ''
    const _isAuthenticated = isAuthenticated()
    React.useEffect(() => {
        if (_isAuthenticated) {
            const [destination, search] = prevLocationPath === '/login' ? ['/', ''] : [prevLocationPath, prevLocationSearch]
            navigate(`${destination}${search}`)
        }
    }, [
        prevLocationPath,
        prevLocationSearch,
        _isAuthenticated,
        navigate,
    ])

    const loginForm = useForm()
    const formModel = {
        username: {
            inputComponent: TextField,
            inputProps: {
                label: 'email',
                defaultValue: user?.username || "",
                autoComplete: 'email',
                error: 'username' in loginForm.formState.errors
            },
            registerOptions: {required: true}
        },
        password: {
            inputComponent: TextField,
            inputProps: {
                type: 'password',
                label: 'password',
                // ...(!user?.username && {defaultValue: 'pw'}),
                autoComplete: 'password',
                error: 'password' in loginForm.formState.errors
            },
            registerOptions: {required: true}
        },
        signingUp: {
            inputComponent: Checkbox
        }
    }


    const submitLogin = (data) => loginMutation.mutate(data)

    return (
        <Paper>
            <AppSnackbar />
            <AppForm formControl={loginForm.control} formModel={formModel} onSubmit={submitLogin}>
                <Grid container display={'flex'} direction={'column'} spacing={1}>

                    <Grid item>
                        <Typography>Login</Typography>
                    </Grid>

                    <Grid item>
                        <AppFormField fieldPath={"username"} />
                    </Grid>

                    <Grid item>
                        <AppFormField fieldPath={"password"} />
                    </Grid>

                    <Grid item>
                        <AppFormField fieldPath={"signingUp"} />
                        Nuovo utente
                    </Grid>

                    <Grid item>
                        <Button variant={"outlined"} type={'submit'}>
                            Login
                        </Button>
                    </Grid>


                </Grid>
            </AppForm>
        </Paper>
    )
}

export default LoginPage