import {IconButton, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import {useUiComponentStore} from "../stateManagement";

export default function AppBarContent() {
    const {setOpen} = useUiComponentStore()

    return (
        <>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, mr: 2 }}>
                App ordinazioni Passage
            </Typography>
            <IconButton onClick={e => {
                e.stopPropagation()
                setOpen(open => !open)
                // commented out because the appbar already does it and stopPropagation isn't working
            }}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                // sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
        </>
    )
}
