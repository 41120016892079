import {createBrowserRouter} from "react-router-dom";
import {Typography} from "@mui/material";
import LoginPage from "./LoginPage";
import Home from "./Home";
import React from "react";
import ProfilePage from "./ProfilePage";
import IndividualOrderPage from "./IndividualOrderPage";
import TotalOrderPage from "./TotalOrderPage";
import HomePage from "./HomePage";

export const router = createBrowserRouter([
    {
        path: "/signup",
        element: <Typography>Signup</Typography>
    },
    {
        path: "/login",
        element: <LoginPage />
    },
    {
        path: "/",
        element: <Home />,
        children: [
            {
                path: "/profile",
                element: <ProfilePage />
            },
            {
                path: "/menu",
                element: <HomePage />
            },
            {
                path: "/total-order",
                element: <TotalOrderPage />
            },
            {
                path: "/",
                element: <IndividualOrderPage />
            }
        ]
    },
])