import React from "react";
import {Collapse, Typography} from "@mui/material";

export default function FormattedJson({_key, value, isLast = true}) {
    const [open, setOpen] = React.useState(true)
    if (value === undefined) {
        return <Typography fontFamily={'monospace'}>{`${_key}: undefined`}{!isLast && ','}</Typography>
    }
    if (value === null) {
        return <Typography fontFamily={'monospace'}>{`${_key}: null`}{!isLast && ','}</Typography>
    }
    if (Array.isArray(value)) {
        return <div>
            <Typography fontFamily={'monospace'} onClick={() => setOpen(isOpen => !isOpen)}>{(_key !== undefined) && _key + ': '}{'['}{!open && ' ... ]' + (isLast ? '' : ',')}</Typography>
            <Collapse in={open} >
                <div style={{marginLeft: '1.5rem'}}>
                    {value.map((item, index, arr) => <FormattedJson key={index} _key={undefined} value={item} isLast={index === arr.length - 1} />)}
                </div>
                <Typography fontFamily={'monospace'}>{']'}{!isLast && ','}</Typography>
            </Collapse>
        </div>
    }
    if (typeof value === 'object') {
        return <div>
            <Typography fontFamily={'monospace'} onClick={() => setOpen(isOpen => !isOpen)}>{(_key !== undefined) && _key + ': '}{'{'}{!open && ' ... }' + (isLast ? '' : ',')}</Typography>
            <Collapse in={open}>
                <div style={{marginLeft: '1.5rem'}}>
                    {Object.entries(value).map(([k, v], index, arr) => <FormattedJson key={k} _key={k} value={v} isLast={index === arr.length - 1} />)}
                </div>
                <Typography fontFamily={'monospace'}>{'}'}{!isLast && ','}</Typography>
            </Collapse>
        </div>
    }
    return <Typography fontFamily={'monospace'}>{(_key !== undefined) && `${_key}: `}{JSON.stringify(value)}{!isLast && ','}</Typography>

}