
export const timeFormatter = new Intl.DateTimeFormat('it-IT', {
    timeZone: 'Europe/Rome',
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
})

export function assert(condition, error) {
    if (!condition) {
        throw error
    }
}

export function validateToken(token) {
    const tokenPayload = JSON.parse(atob(token.split('.')[1]))
    assert(tokenPayload.exp * 1000 > new Date().getTime() + 5 * 60 * 1000, new Error('expired token'))
}

const dateFormat = new Intl.DateTimeFormat("it-IT", {
    timeZone: 'Europe/Rome',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
})
export const toDateString = (date) => dateFormat.format(date).match(/\d+/g).reverse().join('-')
