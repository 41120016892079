import {Box, SwipeableDrawer, Toolbar} from "@mui/material";
import {useUiComponentStore} from "../stateManagement";
import AppDrawerContent from "./AppDrawerContent";

export default function AppDrawer() {

    const {open, setOpen} = useUiComponentStore()

    return (
        <SwipeableDrawer
            anchor={"top"}
            sx={{position: 'relative'}}

            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}

            // variant="temporary"
            // disableSwipeToOpen={false}
            // disableDiscovery={navigator?.userAgent && /iP(hone|ad|od)/.test(navigator.userAgent)}
        >
            <Box width={250} height={'100%'}>
                <Toolbar />
                <AppDrawerContent />
            </Box>
        </SwipeableDrawer>
    )
}