import React from 'react'
import {useMenuQuery} from "../networking/queries";
import FormattedJson from "../components/FormattedJson";
import {Typography} from "@mui/material";

function HomePage() {

    const menuQuery = useMenuQuery()

    if (menuQuery.isFetching) {
        return <Typography fontFamily={"monospace"}>Loading...</Typography>
    }

    return <FormattedJson _key={undefined} value={menuQuery.data}/>
}

export default HomePage
