import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import AppBarContent from "./AppBarContent";
import {useUiComponentStore} from "../stateManagement";
import {useNavigate} from "react-router-dom";
import AppSnackbar from "./AppSnackbar";

export default function CustomAppBar() {

    const {setOpen} = useUiComponentStore()
    const firstTouch = React.useState({})[0] // we don't need setters
    const lastTouch = React.useState({})[0]
    const appBarRef = React.useRef()
    const navigate = useNavigate()

    // record first position in order to be able to compute cumulative move
    const handleTouchStart = React.useCallback((e) => {
        const {clientX, clientY} = e.touches[0]
        const touchPosition = {clientX, clientY}
        Object.assign(firstTouch, touchPosition)
        Object.assign(lastTouch, touchPosition)
    }, [firstTouch, lastTouch])

    // prevent over-scrolling down
    const handleTouchMove = React.useCallback((e) => {
        e.preventDefault()

        const {clientX, clientY} = e.touches[0]
        const touchPosition = {clientX, clientY}

        Object.assign(lastTouch, touchPosition)
        const {deltaY} = getCumulativeTouchMove(firstTouch, touchPosition)

        if (deltaY > 100) {
            setOpen(true)
            Object.assign(firstTouch, touchPosition)
        }
        else if (deltaY < -10) {
            setOpen(false)
            Object.assign(firstTouch, touchPosition)
        }
    }, [setOpen, firstTouch, lastTouch])

    // open the drawer if swiping down, close it if swiping up
    const handleTouchEnd = React.useCallback(() => {
        const {deltaY} = getCumulativeTouchMove(firstTouch, lastTouch)
        if (deltaY > 30) {
            setOpen(true)
        } else if (deltaY < 0) {
            setOpen(false)
        } else {
            // setOpen(open => !open)
        }
    }, [setOpen, firstTouch, lastTouch])

    React.useEffect(() => {
        const appBarElement = appBarRef.current
        if (!appBarElement?.addEventListener) return
        appBarElement.addEventListener('touchstart', handleTouchStart);
        appBarElement.addEventListener('touchmove', handleTouchMove, {passive: false});
        appBarElement.addEventListener('touchend', handleTouchEnd);

        return () => {
            appBarElement.removeEventListener('touchstart', handleTouchStart);
            appBarElement.removeEventListener('touchmove', handleTouchMove);
            appBarElement.removeEventListener('touchend', handleTouchEnd);
        }
    }, [handleTouchStart, handleTouchMove, handleTouchEnd])

    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} ref={appBarRef} onClick={() => setOpen(false) || navigate("/")}>
                <Toolbar>
                    <AppBarContent />
                </Toolbar>
                <AppSnackbar />
            </AppBar>
            <Toolbar />
        </>
    )
}


function getCumulativeTouchMove(firstTouch, lastTouch) {

    const {clientX: firstClientX, clientY: firstClientY} = firstTouch
    const {clientX: lastClientX, clientY: lastClientY} = lastTouch

    const deltaX = lastClientX - firstClientX
    const deltaY = lastClientY - firstClientY

    return {deltaX, deltaY}
}
