import {axiosInstance, axiosUnauthenticatedInstance} from "./query-client";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useAuthStore, useUiComponentStore} from "../stateManagement";
import {toDateString} from "../utils";

export const helloWorld = {
    name: 'hello-world',
    fn: () => axiosInstance.get('/').then(resp => console.log(resp))
}

export const authQueries = {
    login: {
        name: 'login',
        fn: async ({username, password, signingUp}) => {
            const res = await axiosUnauthenticatedInstance.post('/login', {username, password, signingUp})
            return res.data
        }
    }
}

export const menuQueries = {
    getMenu: {
        name: 'getMenu',
        fn: async () => {
            const res = await axiosInstance.get('/menu')
            return res.data
        }
    }
}

export function useLoginMutation() {
    const setToken = useAuthStore(state => state.setToken)
    const showSnackbar = useUiComponentStore(state => state.showSnackbar)
    return useMutation({
        mutationKey: [authQueries.login.name],
        mutationFn: authQueries.login.fn,
        onSuccess: (data) => {
            setToken(data.token)
            showSnackbar({}, {severity: 'success', children: 'logged in'})
        },
        onError: (err) => {
            console.error('login failed');
            showSnackbar({}, {children: `login failed: ${err.message}`})
        }
    })
}

export function useMenuQuery() {
    const isAuthenticated = useAuthStore(state => state.isAuthenticated)
    const showSnackbar = useUiComponentStore(state => state.showSnackbar)
    return useQuery({
        queryKey: [menuQueries.getMenu.name, Math.floor(new Date().getTime() / (1000 * 60 * 15))],
        queryFn: () => menuQueries.getMenu.fn().then(data => {
            console.log('fetched menu', data)
            if (toDateString(new Date()) !== data.lastUpdate) {
                showSnackbar({autoHideDuration: 10000000}, {severity: 'warning', children: 'è ancora il menu di ieri'})
            } else {
                showSnackbar({autoHideDuration: 5000}, {severity: 'success', children: 'il menu è aggiornato'})
            }
            return data
        }),
        enabled: isAuthenticated(),
        onError: () => {
            console.error('menu query failed')
            showSnackbar({}, {severity: 'error', children: 'Failed to load menu'})
        },
        onSuccess: (data) => {
            console.log('fetched menu', data)
            // if (toDateString(new Date()) !== data.lastUpdate) {
            //     showSnackbar({}, {severity: 'warning', children: 'Il menù non è aggiornato'})
            // }
        },
    })
}

export function useIndividualOrderQuery(menu) {
    const isAuthenticated = useAuthStore(state => state.isAuthenticated)
    const showSnackbar = useUiComponentStore(state => state.showSnackbar)
    return useQuery({
        queryKey: ['getIndividualOrder'],
        queryFn: async () => {
            const res = await axiosInstance.get('/user/order')
            return res.data
        },
        enabled: isAuthenticated() && !!menu,
        onError: () => {
            console.error('individual order query failed')
            showSnackbar({}, {severity: 'error', children: 'Failed to load order'})
        }
    })
}

export function useIndividualOrderMutation(menu) {
    const showSnackbar = useUiComponentStore(state => state.showSnackbar)
    return useMutation({
        mutationKey: ['postIndividualOrder'],
        mutationFn: async (order) => {
            const res = await axiosInstance.post('/user/order', order)
            return res.data
        },
        enabled: !!menu,
        onError: (err) => {
            console.error('individual order mutation failed', err)
            showSnackbar({}, {severity: 'error', children: 'Failed to update order'})
        },
        onSuccess: () => {
            showSnackbar({}, {severity: 'success', children: 'Updated order'})
        }
    })
}

export function useTotalOrderQuery() {
    const showSnackbar = useUiComponentStore(state => state.showSnackbar)
    const isAuthenticated = useAuthStore(state => state.isAuthenticated)
    return useQuery({
        queryKey: ['getTotalOrder'],
        queryFn: async () => {
            const res = await axiosInstance.get('/order')
            // showSnackbar({}, {severity: 'success', children: 'updated'})
            return res.data
        },
        enabled: isAuthenticated(),
        onError: () => {
            console.error('total order query failed')
            showSnackbar({}, {severity: 'error', children: 'Failed to load order'})
        },
        // refetchInterval: 1000
    })
}
