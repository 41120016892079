import {QueryClient} from "@tanstack/react-query";
import axios from "axios";
import {apiUrl} from "../configuration/constants";
import {useAuthStore} from "../stateManagement";
import {validateToken} from "../utils";

export const queryClient = new QueryClient()

const axiosAuthenticatedInstance = axios.create()
axiosAuthenticatedInstance.interceptors.request.use((config) => {
    const persistedState = JSON.parse(localStorage.getItem(useAuthStore.persist.getOptions().name))?.state
    const token = persistedState?.token
    try {
        validateToken(token)
    } catch (err) {
        throw new Error('invalid token')
    }
    config.headers.Authorization = 'Bearer ' + token
    config.withCredentials = true
    config.baseURL = apiUrl
    return config;

}, (error) => {
    console.error('request error', error)
    return Promise.reject(error);
});

axiosAuthenticatedInstance.interceptors.response.use((response) => {
    // Do something with response data
    return response;
}, (error) => {
    // Do something with response error
    console.error('response error', error)
    if (error?.response?.status === 401) {
        const persistedState = JSON.parse(localStorage.getItem(useAuthStore.persist.getOptions().name))
        persistedState.state.token = null
        localStorage.setItem(useAuthStore.persist.getOptions().name, JSON.stringify(persistedState))
        useAuthStore.persist.rehydrate()
    }
    return Promise.reject(error);
});

export const axiosInstance = axiosAuthenticatedInstance

export const axiosUnauthenticatedInstance = axios.create({
    baseURL: apiUrl
})
