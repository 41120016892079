import React from "react";
import {Collapse, Fab, Grid, Paper, Typography, useTheme} from "@mui/material";
import AppForm from "../components/AppForm";
import {useForm} from "react-hook-form";
import {useIndividualOrderMutation, useIndividualOrderQuery, useMenuQuery} from "../networking/queries";
import AppFormField from "../components/AppFormField";
import {useUiComponentStore} from "../stateManagement";

function IndividualOrderPage() {
    const theme = useTheme()
    const showSnackbar = useUiComponentStore(state => state.showSnackbar)

    const menuQuery = useMenuQuery()
    const menu = !menuQuery.data?.menu ? undefined : Object.assign(menuQuery.data.menu, {
        bevande: [
            {name: 'bicchiere naturale', price: 0.5},
            {name: 'bicchiere frizzante', price: 0.5},
            {name: 'bottiglietta naturale', price: 1},
            {name: 'bottiglietta frizzante', price: 1},
            {name: 'coca cola', price: 3.5},
            {name: 'coca cola zero', price: 3.5}
        ],
        opzioni: [
            {name: 'formaggio'}
        ]
    })

    const orderForm = useForm()
    const formValues = orderForm.watch()

    const individualOrderMutation = useIndividualOrderMutation(menu)
    const individualOrderQuery = useIndividualOrderQuery(menu)
    React.useEffect(() => {
        if (!individualOrderQuery.data || !menu) return
        orderForm.reset(beToFe(individualOrderQuery.data, menu));
    }, [individualOrderQuery.data, orderForm, menu])

    const combinazioneEnabled = ['primi', 'secondi', 'contorni'].filter(section => section in (formValues?.combinazioni || {}) && formValues?.combinazioni[section] !== "").length >= 2
    const combinazioneSelezionata = combinazioneEnabled && ['primi', 'secondi', 'contorni'].filter(section => section in (formValues?.combinazioni || {})).filter(section => !!formValues.combinazioni[section]).map(text => text.replace(/^./, char => char.toUpperCase()).replace(/.$/, 'o')).join(' + ')
    const altroLength = formValues?.altro?.at(-1) ? formValues.altro.length + 1 : formValues?.altro?.length || 1

    React.useEffect(() => {
        orderForm.setValue("combinazioni.checked", combinazioneEnabled)
    }, [combinazioneEnabled, orderForm])

    const orderFormConfig = menu && {
        combinazioni: {
            checked: {inputComponent: 'input', inputProps: {type: 'checkbox'}, registerOptions: {disabled: !combinazioneEnabled}},
            ...Object.fromEntries(
                ['primi', 'secondi', 'contorni']
                .filter(section => section in menu)
                .map(section => [section, {inputComponent: 'select', inputProps: {type: 'select', style: {width: '95%'}}}])
            )
        },
        ...Object.fromEntries(
            ['pinse', 'poke', 'primi', 'secondi', 'contorni', 'piattiUnici', 'insalate', 'bevande', 'opzioni']
            .filter(section => section in menu)
            .map(section => [
                section,
                menu[section].map(() => ({checked: {inputComponent: 'input', inputProps: {type: 'checkbox'}}}))
            ])
        ),
        altro: Array(altroLength)
            .fill(null)
            .map(() => ({inputComponent: 'input', inputProps: {style: {width: '95%'}}})),
        note: {inputComponent: 'textarea', inputProps: {style: {width: '95%', height: 50}}}
    }

    function handleSubmit(data) {
        const order = feToBe(data, menu)
        individualOrderMutation.mutate(order, {onSuccess: (data) => {
            if (!order.orderData?.bevande) {
                showSnackbar({autoHideDuration: 5000}, {severity: 'info', children: "l'acqua?"})
            }
            else if (!order.orderData?.opzioni) {
                showSnackbar({}, {severity: 'warning', children: 'Non hai richiesto il formaggio. Se lo desideri, aggiorna l\'ordine'})
            }
            orderForm.reset(beToFe(data, menu))
        }})
    }


    if (menuQuery.isLoading) {
        return <Typography>Loading...</Typography>
    }

    const prezzoCombinazione = combinazioneSelezionata && menu.combinazioni.find(({name}) => name === combinazioneSelezionata)?.price

    return (
        <Paper>
            <AppForm formControl={orderForm.control} formModel={orderFormConfig} onSubmit={handleSubmit}>
                <Grid container spacing={2}>

                    <Grid item container direction={'column'} spacing={1}>
                        <Grid item>
                            <Typography variant={"h5"}>Combinazioni</Typography>
                        </Grid>
                        <Grid item container spacing={1} wrap={"nowrap"} alignItems={'center'}>
                            <Grid item>
                                <AppFormField fieldPath={"combinazioni.checked"} />
                            </Grid>
                            <Grid item container wrap={"wrap"} spacing={0.5}>
                                {['primi', 'secondi', 'contorni'].filter(section => section in (orderFormConfig.combinazioni)).map(section => (
                                    <Grid item xs={12} sm={4} key={section}>
                                        <AppFormField fieldPath={`combinazioni.${section}`}>
                                            <option value={""} label={`${section} - nessuno`}/>
                                            {menu[section].map(({name}) => (
                                                <option value={name} key={name} label={name}/>
                                            ))}
                                        </AppFormField>
                                    </Grid>
                                ))}
                            </Grid>
                            {prezzoCombinazione && <Grid item>
                                <Typography noWrap={true} color={theme.palette.text.secondary}>
                                    € {prezzoCombinazione.toFixed(2)}
                                </Typography>
                            </Grid>}
                        </Grid>
                    </Grid>

                    {['primi', 'secondi', 'contorni', 'piattiUnici', 'insalate', 'pinse', 'poke', 'bevande', 'opzioni']
                        .filter(sectionName => sectionName in orderFormConfig)
                        .map(sectionName => (
                            <Grid item container direction={'column'} spacing={1} key={sectionName}>
                                <Grid item>
                                    <Typography variant={"h5"}>{addSpacesAndCapitalize(sectionName)}</Typography>
                                </Grid>
                                <Grid item>
                                    {menu[sectionName].map(({name, ingredients, price}, index) => (
                                        <Grid item container spacing={1} wrap={"nowrap"} alignItems={'center'} key={name}>
                                            <Grid item>
                                                <AppFormField fieldPath={`${sectionName}.${index}.checked`} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>{name}</Typography>
                                                {!!ingredients && <Typography color={theme.palette.text.secondary}>{ingredients}</Typography>}
                                            </Grid>
                                            {price && <Grid item>
                                                <Typography noWrap={true} color={theme.palette.text.secondary}>
                                                    € {price.toFixed(2)}
                                                </Typography>
                                            </Grid>}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ))
                    }

                    <Grid item container direction={'column'} spacing={1}>
                        <Grid item>
                            <Typography variant={"h5"}>{addSpacesAndCapitalize("altro")}</Typography>
                        </Grid>
                        <Grid item>
                            {sequenceArray(altroLength).map(i => (
                                <Collapse in={!formValues?.altro || !!formValues.altro.at(i) || i === altroLength - 1} key={i}>
                                    <Grid item container spacing={1} wrap={"nowrap"} alignItems={'center'} key={i}>
                                        <Grid item xs>
                                            <AppFormField fieldPath={`altro.${i}`} />
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item container direction={'column'} spacing={1}>
                        <Grid item>
                            <Typography variant={"h5"}>{addSpacesAndCapitalize("note")}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid item container spacing={1} wrap={"nowrap"} alignItems={'center'}>
                                <Grid item xs>
                                    <AppFormField fieldPath={`note`} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*<Grid item>*/}
                    {/*    <Button variant={"outlined"} type={'submit'}>*/}
                    {/*        Conferma*/}
                    {/*    </Button>*/}
                    {/*</Grid>*/}
                </Grid>
                <Fab variant="extended"
                     size={"small"}
                     type={"submit"}
                     style={{position: "fixed", right: 10, bottom: 10}}
                    disabled={!orderForm.formState.isDirty || orderForm.formState.isLoading}
                >

                    Conferma
                </Fab>
            </AppForm>
        </Paper>
    )
}

export default IndividualOrderPage



function addSpacesAndCapitalize(text) {
    return text.replace(/(?<=[a-z])(?=[A-Z])/g, ' ').replace(/^[a-z]/, character => character.toUpperCase())
}

function beToFe({orderData}, menu) {
    orderData ||= {}
    const data = {}

    data.combinazioni = {
        checked: !!orderData?.combinazioni,
        primi: orderData?.combinazioni?.primi || "",
        secondi: orderData?.combinazioni?.secondi || "",
        contorni: orderData?.combinazioni?.contorni || ""
    };

    ['primi', 'secondi', 'contorni', 'piattiUnici', 'insalate', 'pinse', 'poke', 'bevande', 'opzioni']
        .forEach(section => {
            if (!(section in orderData && section in menu)) return
            data[section] = menu[section]
                .map(({name}) => ({checked: orderData[section].indexOf(name) >= 0}))
        })
    data.altro = [...(orderData?.altro || []), ""]
    data.note = orderData?.note || ""
    return data
}

function feToBe(data, menu) {
    const orderData = {};
    if (data?.combinazioni?.checked) {
        orderData.combinazioni = Object.fromEntries(Object.entries(data.combinazioni).filter(([section, name]) => section !== 'checked' && !!name))
    }
    ['primi', 'secondi', 'contorni', 'piattiUnici', 'insalate', 'pinse', 'poke', 'bevande', 'opzioni']
        .forEach(section => {
            if (!(section in data && section in menu)) return
            zip(data[section], menu[section])
            .filter(([dataItem, ]) => dataItem.checked)
            .forEach(([dataItem, menuItem]) => {
                orderData[section] ||= []
                orderData[section].push(menuItem.name)
            })
    });
    data.altro.filter(item => !!item).forEach(item => {
        orderData.altro ||= []
        orderData.altro.push(item)
    })
    if (!!data.note) {
        orderData.note = data.note
    }
    console.log({orderData})
    return {orderData}
}

function zip(arr1, arr2) {
    arr1 ||= []
    arr2 ||= []
    const out = []
    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
        out.push([arr1[i], arr2[i]])
    }
    return out
}

function sequenceArray(n) {
    let i = 0
    return Array(n).fill(undefined).map(() => i++)
}


