import React from "react";
import {Typography} from "@mui/material";

function ProfilePage() {

    return (
        <Typography variant={'h2'}>Profilo</Typography>
    )
}

export default ProfilePage