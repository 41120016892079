import React from "react";

import "./App.css";

import {QueryClientProvider} from "@tanstack/react-query";
import {createTheme, ThemeProvider} from "@mui/material";

import {RouterProvider} from "react-router-dom";

import {queryClient} from "./networking/query-client";
import {useAuthStore, useThemeStore} from "./stateManagement";
import {router} from "./routes/router";


function App() {
    const themeMode = useThemeStore(state => state.themeMode)
    const theme = React.useMemo(() => createTheme({palette: {mode: themeMode}, spacing: 2}), [themeMode])
    const [login, token] = useAuthStore(state => [state.login, state.token])

    React.useEffect(() => {
        token && login()
    }, [login, token])

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>

                <RouterProvider router={router} />
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
