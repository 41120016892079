import {Grid, List, ListItemButton, ListItemIcon, ListItemText, Switch} from "@mui/material";
import React from "react";
import {useAuthStore, useUiComponentStore, useThemeStore} from "../stateManagement";

import {useNavigate} from "react-router-dom";

export default function AppDrawerContent() {

    const [setToken, user] = useAuthStore(state => [state.setToken, state.user])

    const {isDarkMode, toggleDarkMode} = useThemeStore()
    const setDrawerOpen = useUiComponentStore(state => state.setOpen)
    const navigate = useNavigate()

    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

            width: '100%'
        }}>
            <Grid item width={'100vw'}>
                <List>
                    {user && (
                        <ListItemButton onClick={() => setDrawerOpen(false) || navigate('/profile')}>
                            <ListItemText
                                primary="Profilo"
                                secondary={user.username}
                            />
                        </ListItemButton>
                    )}
                    <ListItemButton onClick={() => setDrawerOpen(false) || navigate('/')}>
                        <ListItemText primary="Ordinazione" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setDrawerOpen(false) || navigate('/total-order')}>
                        <ListItemText primary="Riepilogo" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setDrawerOpen(false) || setTimeout(() => window.open("/menu.pdf"))}>
                        <ListItemText primary="Menù pdf" />
                    </ListItemButton>
                    <ListItemButton onClick={toggleDarkMode}>
                        <ListItemText primary="Dark mode" />
                        <ListItemIcon>
                            <Switch checked={isDarkMode()} />
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setDrawerOpen(false) || setTimeout(() => setToken(undefined), 500)}>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </List>
            </Grid>
        </Grid>
    )
}
