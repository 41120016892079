import React from "react"
import {Form} from "react-router-dom";

export const AppFormContext = React.createContext(null)

export default function AppForm({formControl, formModel, onSubmit, children}) {

    return (
        <AppFormContext.Provider value={{formControl, formModel}}>
            <Form onSubmit={formControl.handleSubmit(onSubmit)}>
                {children}
            </Form>
        </AppFormContext.Provider>
    )
}