import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import {assert} from "./utils";

export const useThemeStore = create(persist(
    (set, get) => ({
        themeMode: 'dark',
        isDarkMode: () => get().themeMode === 'dark',
        toggleDarkMode: () => set({themeMode: (get().themeMode === 'dark' ? 'light' : 'dark')})
    }),
    {
        name: 'theme-store',
        storage: createJSONStorage(() => localStorage),
    }
))

export const useUiComponentStore = create((set, get) => ({
    open: false,
    setOpen: (open) => typeof open === 'function' ? set({open: open(get().open)}) : set({open}),
    // setOpen: boundStoreSetter(set, get, 'open'),

    isSnackbarOpen: false,
    snackbarProps: undefined,
    alertProps: undefined,
    showSnackbar: (snackbarProps, alertProps) => {
        set({snackbarProps, alertProps, isSnackbarOpen: true})
    },
    hideSnackbar: () => set({isSnackbarOpen: false})
}))

export const useAuthStore = create(persist(
    (set, get) => ({
        user: null,
        token: null,

        isAuthenticated: () => !!get().token,

        setToken: (token) => {
            set({token})
            // get().login()
        },

        login: () => {
            try {
                const parsedToken = JSON.parse(atob(get().token.split('.').at(1)))
                assert(parsedToken.exp * 1000 > new Date().getTime() + 60 * 1000, new Error('token expired'))
                set({user: parsedToken.userInfo, token: get().token})

            } catch (err) {
                console.error(err.message)
                // get().logout()
            }
        },

        logout: () => {
            // axiosInstance.defaults.headers.common.Authorization = undefined
            set({
                user: null,
                token: undefined,
            })
        }
    }),
    {
        name: 'auth-store',
        storage: createJSONStorage(() => localStorage),
        // partialize: (state) => ({user: state.user, token: state.token}),
        merge: (persistedState, currentState) => {
            try {
                const tokenPayload = JSON.parse(atob(persistedState.token.split('.')[1]))
                assert(tokenPayload.exp * 1000 > new Date().getTime() + 5 * 60 * 1000, new Error('expired token'))
            } catch (err) {
                console.error(err.message)
                currentState.token = null
                return Object.assign({}, currentState)
            }
            return Object.assign({}, currentState, persistedState)
        }


    }
))

export function useUserData() {
    return useAuthStore(state => state.user)
}