import React from "react";
import {Button, Grid, Stack, Typography} from "@mui/material";
import {useTotalOrderQuery} from "../networking/queries";
import {useUiComponentStore} from "../stateManagement";

function TotalOrderPage() {

    const textAreaRef = React.useRef()
    const showSnackbar = useUiComponentStore(state => state.showSnackbar)
    // const userData = useUserData()
    const {data} = useTotalOrderQuery()


    React.useEffect(() => {
        if (!data || !textAreaRef?.current) return
        textAreaRef.current.textContent = data
            .filter(({orderData}) => !!Object.keys(orderData).length)
            .map(formatOrder)
            .join('\n\n')
    }, [data])

    return (
        <Grid container spacing={2} direction={'column'}>
            <Grid item xs>
                <Typography variant={'h5'}>Ordine complessivo</Typography>
            </Grid>
            <Grid item xs>
                <pre ref={textAreaRef} contentEditable style={{overflow: "scroll"}} />
            </Grid>
            <Grid item>
                <Stack direction={"row"} spacing={5}>
                    <Button variant={"contained"} onClick={() => {
                        navigator.clipboard.writeText(textAreaRef.current?.textContent).then(() => showSnackbar({}, {severity: 'info', children: 'copiato!'}))
                    }}>Copia</Button>

                    <Button variant={"contained"} onClick={() => {
                        const url = `https://api.whatsapp.com/send`
                        const phone = '+393519608454'
                        const text = textAreaRef.current?.textContent
                        navigator.clipboard.writeText(text).then(() => window.open(`${url}?phone=${phone}$text=Ciao`))
                    }}>Copia e apri la chat</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default TotalOrderPage

function getShortenedText(text) {
    return (text + '   ').replace(/ \S{1,3}(?= )/g, '').match(/(.*? ){3}/)[0].trim().replace(/[^a-zàáèéìíòóùú]*$/i, '')
}

function formatOrder({username, orderData}, index, allOrders) {
    username = username.replace(/@.*/, '')
    let {combinazioni = {}, insalate = [], pinse = [], altro = [], opzioni = [], note, ...etc} = orderData
    let {primi, secondi, contorni} = combinazioni


    const formaggio = opzioni.find(el => el === 'formaggio')
    if (primi && formaggio) primi = getShortenedText(primi).trim() + '+form'
    if (etc?.primi && formaggio) etc.primi = etc.primi.map(el => getShortenedText(el).trim() + '+form')

    const rows = [`*(${index + 1}/${allOrders.length}) ${username}*:`]

    const combinato = [undefined, undefined, "Bis", "Tris"][[primi, secondi, contorni].reduce((acc, cur) => acc + !!cur, 0)]
    if (combinato) {
        rows.push(`- ${combinato}: _${[primi, secondi, contorni].filter(el => !!el).map(getShortenedText).join(', ').trim()}_;`)
    }
    rows.push(...pinse.map(item => `- Pinsa _${getShortenedText(item).trim()}_;`))
    rows.push(...insalate.map(item => `- Insalata _${(item.trim())}_;`))
    rows.push(...Object.values(etc).flatMap(x => x).map(piatto => `- ${getShortenedText(piatto)};`))
    rows.push(...altro.map(item => `- ${item.trim()};`))

    if (note) {
        rows.push(`*note: ${note.trim()}*`)
    }
    return rows.join('\n')
}