import React from "react";

import AppBar from "../components/AppBar";
import AppDrawer from "../components/AppDrawer";
import {Outlet, Navigate, useLocation} from "react-router-dom";
import {useAuthStore} from "../stateManagement";


function Home() {
    const [isAuthenticated] = useAuthStore(state => [state.isAuthenticated])

    const location = useLocation()

    if (!isAuthenticated()) {
        return <Navigate to={`/login`} replace state={{ prevLocation: location }} />
    }

    return (
        <>
            <AppBar />
            <AppDrawer />

            <Outlet />
        </>
    );
}

export default Home;