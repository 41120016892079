import React from "react"
import {Alert, Snackbar} from "@mui/material";
import {useUiComponentStore} from "../stateManagement";

function AppSnackbar () {
    const [isOpen, close, snackbarProps, alertProps] = useUiComponentStore(state => [
        state.isSnackbarOpen,
        state.hideSnackbar,
        state.snackbarProps,
        state.alertProps
    ])
    return (
        <div style={{height: 0, overflow: "visible", position: "relative"}}>
            <Snackbar
                style={{position: 'absolute'}}
                autoHideDuration={2000}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                {...snackbarProps}
                open={isOpen}
                onClose={close}
            >
                <Alert
                    severity={"error"}
                    children={"error"}
                    variant="filled"
                    sx={{ position: 'relative' }}
                    {...alertProps}
                    onClose={close}
                />

            </Snackbar>
        </div>
    )
}

export default AppSnackbar